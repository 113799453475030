<script setup>
const route = useRoute()
useHead({
  title: "Program poleceń - Vehis.pl",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {rel: 'canonical', href: `https://vehis.pl${route.fullPath}`}
  ]

});

const slides = useSlides();

const refControl = ref();
const refSlideNavigation = ref();

onMounted(() => slides.initSlides(refControl, refSlideNavigation))
const faqData =  [
  {
    question: "Jak obliczany jest 1% premii?",
    answer:
      "Premię 1% obliczamy od wartości udzielonego finansowania, czyli wartości leasingowanego samochodu pomniejszonej o opłatę wstępną wpłaconą przez klienta.<br/><br/>Przykład: Klient podpisuje umowę leasingową na samochód o wartości 200 000 zł i wpłaca 25% opłaty wstępnej. Wartość finansowania wynosi zatem 150 000 zł, a Ty otrzymujesz 1 500 zł wynagrodzenia.",
  },
  {
    question: "Czy ilość umów leasingowych z mojego polecenia jest ograniczona?",
    answer:
      "Możesz polecać VEHIS bez limitu, a my wypłacimy wynagrodzenie za każde skuteczne polecenie. Tylko od Ciebie zależy, ile zarobisz dzięki współpracy z nami.",
  },
  {
    question: "Kiedy Twoje polecenie będzie skuteczne?",
    answer:
      "Gdy klient podpisze umowę leasingową i wpłaci ustaloną opłatę wstępną.",
  },
  {
    question: "W jaki sposób wypłacane jest wynagrodzenie za polecenie?",
    answer:
      "Wynagrodzenie wypłacimy na podstawie wystawionej przez Ciebie faktury.",
  },
  {
    question: "Kiedy otrzymam wynagrodzenie za polecenie?",
    answer:
      "Do 10 dni po zakończeniu miesiąca kalendarzowego udostępnimy Ci w formie elektronicznej zestawienie Umów Produktowych zrealizowanych z klientami z Twojego polecenia. Wynagrodzenie wypłacimy w terminie 14 dni od otrzymania od Ciebie faktury.",
  }
]
</script>

<template>
  <Container>
    <StaticMainHeader 
        isRpPage
      :h1="'POLECAJ'" 
      :h2="'Samochód na raty'" 
      :heroBoxesData="[]"
      :text="'Doskonała oferta nabycia samochodu bez działalności gospodarczej.'">
      <template v-slot:mainImage>
        <FormRpAccounting analytics-form-type="rp-zarabiaj-1%"  noContainer :customTextBtn="'Wyślij'" title='Wpisz dane <p class="text-azure inline-block">polecanego klienta!</p>' standalone />
      </template>
      <template v-slot:headerDescription>
            <div class="flex gap-4 items-center mt-12 flex-col lg:flex-row">
                <img class="max-w-36 max-h-36" src="@/assets/images/statics/rp-circle-promo.png" alt="" />
                <div>
                    <p class="text-3xl text-coolGray-700">
                        <span class="font-normal">premii</span><span class="font-extralight"> od wartości każdej umowy leasingu z Twojego </span><span class="font-normal">polecenia.</span>
                    </p>
                </div>
            </div>
      </template>
    </StaticMainHeader>
  </Container>
      <StaticCtaPhone/>
      <section id="faq">
        <StaticFaq :faqData="faqData"/>
        <NuxtLink to="/informacje/program-partnerski" class="mt-16 text-center block text-azure text-base font-normal mb-16 md:mb-[144px]">Regulamin programu partnerskiego PREMIA 1%</NuxtLink>
      </section>
    <div class="bg-cool-gray-light">
      <Container>
        <SeoBrandsCloud noOuterPaddings />
      </Container>
    </div>
  </template>
  <style>
html {
  scroll-behavior: smooth;

}

#contactSection {
  scroll-margin-top: 150px;
}
</style>